import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import './DynBrands.css';
import Products from './Products';
import InfoCard2 from './InfoCards2';
import { useLocation } from 'react-router-dom';

export default function DynBrands() {
  const [Info, setInfo] = useState([]);
  const [brandId, setBrandId] = useState(null); // State to hold the selected brandId
  const [brandName, setbrandName] = useState('')
  // const [ItemId, setItemId] = useState(null); // State to hold the selected brandId
  // const [ItemName, setItemName] = useState('')
  const scrollContainerRef = useRef(null); // Reference for the scrollable container

  const itemId = sessionStorage.getItem('itemId');
    const itemName = sessionStorage.getItem('itemName');

  console.log('bbbbbbbbbbbb',itemId,itemName);
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const res = await axios.post('http://localhost:5000/proxy/brandCategorypoint', {
        //  const res = await axios.post('http://192.168.0.139:9000/proxy/brandpoint', {
        const res = await axios.post('http://midbserver.co.in:8285/trading_application/rest/menuService/getBrandCategoryDetails', {
          // one: itemId,
          authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
        });
        setInfo(res.data); // Store response data in state
        console.log(res.data); // To verify the structure of response
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  // Function to scroll the container to the right
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: window.innerWidth <= 768 ? 150 : 200, // Adjust scroll distance based on screen size
        behavior: 'smooth', // Smooth scrolling effect
      });
    }
  };

  // Function to scroll the container to the left
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: window.innerWidth <= 768 ? -150 : -200, // Adjust scroll distance based on screen size
        behavior: 'smooth', // Smooth scrolling effect
      });
    }
  };

  // Handle item click and set the selected brandId
  const handleItemClick = (id, name) => {
    setBrandId(id);  // Update the brandId state when an item is clicked
    setbrandName(name)
    console.log("Selected Brand ID: ", id); // You can use this brandId for any further logic
    console.log("Selected Brand Name: ", name); // You can use this brandId for any further logic
  };


  return (
    <>
      <div className='container'>
        <div className='container' style={{paddingTop:"100px"}}>
          <h3 style={{ color: "rgb(255, 0, 79)", fontWeight: "900px", textAlign: "center" }}>Categories of the brand {itemName} are here.</h3>
          <div className="container d-flex justify-content-center align-items-center" style={{ position: 'relative', marginBottom: "0px" }} >
            {/* Scrollable container */}
            <div
              ref={scrollContainerRef}
              className="row d-flex justify-content-start align-items-center"
              style={{
                overflowX: 'auto', // Allow horizontal scrolling
                display: 'flex',
                flexWrap: 'nowrap',
                paddingBottom: '0px',
                scrollBehavior: 'smooth', // Smooth scroll when user clicks buttons
                width: '100%', // Ensure the container takes up full width
                scrollbarWidth: "none",
                marginBottom: "0px"
              }}
            >
              {Info.length > 0 ? (
                Info.map((item, index) => (
                  <div
                    className="col-12 col-lg-2 col-md-4 col-sm-6"
                    key={index}
                    style={{
                      display: 'inline-block',
                      marginRight: '90px',
                      minWidth: '180px', // Adjust min-width for responsiveness
                    }}
                  >
                    <div className="d-flex flex-column mb-3">
                      {/* Image section */}
                      <div className="p-2 d-flex justify-content-evenly">
                        <img
                          src={item.btc_category_img1 ? `http://midbserver.co.in:8285/MI_Applications/BTrading/BrandLogo/2024/December/${item.btc_category_img1}` : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='}

                         // src={item.btc_category_img1 || 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='} // Fallback image if not available
                          className="transition-imageIN"
                          alt={item.btc_category_name}      
                          style={{
                            height: '150px',
                            width: '150px',
                            boxShadow: '5px 5px 10px gray',
                            borderRadius: "50%",
                            cursor: "pointer",
                            position: "relative", // Ensure it's properly positioned
                            zIndex: 10, // Make sure it appears above other content
                             border: "2px solid #9b9b9b"
                          }}
                          onClick={() => handleItemClick(item.btc_category_id, item.btc_category_name)} // Update brandId on click
                          title="Click here to show products" // Tooltip text for the image
                        />
                      </div>


                      {/* Brand name section */}
                      <div className="p-0" style={{ textAlign: 'center' }}>
                        <p
                          className="nav-linked"
                          style={{
                            fontSize: '15px',
                            color: "#0d778e",
                            fontWeight: "700",
                            cursor: "pointer",
                            whiteSpace: "nowrap", // Prevent text from wrapping
                            overflow: "hidden",  // Hide the overflow text
                            textOverflow: "ellipsis", // Add ellipsis (...) at the end
                            maxWidth: "180px",   // Set a max width based on your design needs
                            display: "inline-block", // Keep the element inline for truncation
                           
                          }}
                          title={item.btc_category_name} // Show full name as tooltip
                          onClick={() => handleItemClick(item.btc_category_id, item.btc_category_name)} // Update brandId on click
                        >
                          <b>{item.btc_category_name}</b>
                        </p>
                      </div>
                    </div>

                  </div>
                ))
              ) : (
                <p>Loading data...</p>
              )}
            </div>

            {/* Left Scroll Button */}
            <div
              style={{
                position: 'absolute',
                left: '-75px',
                top: '35%',
                transform: 'translateY(-50%)',
                backgroundColor: '#0d778e',
                border: 'none',
                borderRadius: '50%',
                padding: '10px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 1, // Ensure it's on top of the content
              }}
              onClick={scrollLeft}
            >
              <FaChevronLeft color="white" size={20} />
            </div>

            {/* Right Scroll Button */}
            <div
              style={{
                position: 'absolute',
                right: '-75px',
                top: '35%',
                transform: 'translateY(-50%)',
                backgroundColor: '#0d778e',
                border: 'none',
                borderRadius: '50%',
                padding: '10px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 1, // Ensure it's on top of the content
              }}
              onClick={scrollRight}
            >
              <FaChevronRight color="white" size={20} />
            </div>

          </div>
          <Products brandId={brandId} brandName={brandName} />
          <InfoCard2 />
        </div>
      </div>
    </>

  );
}

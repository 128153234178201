import React, { useEffect, useState } from 'react';
import './ProductModal.css'; // Import the CSS file
import { Link } from 'react-router-dom';
import './SimilarProduct.css'
// In ProductModal.js
import SimilarProductModal from './SimilarProductModal.js'; // Make sure you're using the default import



const ProductModal = ({ product, show, onClose,comparisonList, addProductToComparison }) => {
  const [showSimilarModal, setShowSimilarModal] = useState(false);
  // const [comparisonList, setComparisonList] = useState(
  //   JSON.parse(sessionStorage.getItem('comparisonList')) || []
  // );
  const [addProductMessage, setAddProductMessage] = useState('');

  
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'auto'; // Re-enable scrolling
    }

    // Save comparison list to session storage on change
    sessionStorage.setItem('comparisonList', JSON.stringify(comparisonList));
    console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaa2', JSON.parse(sessionStorage.getItem('comparisonList')));

    // Cleanup: re-enable scrolling when the component unmounts or modal closes
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [show, comparisonList]);

  const handleAddToComparison = () => {
    addProductToComparison(product.btp_product_id); // Add the current product to comparison list

    setAddProductMessage('Product added to comparison');

    // Remove the message after 2 seconds
    setTimeout(() => {
      setAddProductMessage('');
    }, 2000); // Message will disappear after 2 seconds
  };

  if (!show || !product) return null; // Don't render modal if not visible or no product selected

  //show similar products
  const handleShowSimilarProducts = () => {
    setShowSimilarModal(true); // Show the SimilarProductModal
  };
  const closeSimilarModal = () => {
    setShowSimilarModal(false); // Close the SimilarProductModal
  };

  if (!show || !product) return null; // Don't render modal if not visible or no product selected


  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="modal-close-button" onClick={onClose}>×</span>
        <h4 style={{ textAlign: "center", color: "#0d778e", fontWeight: "700" }}>Product Name: {product.btp_product_name}</h4>

        <div className='container'>
          <div className='row'>
            <div className='col col-lg-6'>
              <img
                src={product.btp_image1 ? `http://midbserver.co.in:8285/MI_Applications/BTrading/BrandLogo/2024/December/${product.btp_image1}` : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='}
                alt={product.series_name}
                className="modal-image"
                style={{ height: "250px", width: "300px" }}
              />
            </div>   {/* first col end here */}
            <div className='col col-lg-6'>
              <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between" }}><strong>Price:</strong> {product.btp_mrp_price}</p>
              <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between" }}><strong>Brand:</strong> {product.btb_brand_name}</p>
              <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between" }}><strong>Status:</strong> {product.btp_product_status}</p>
              <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between" }}><strong>Dimension:</strong> {product.btp_dimension}</p>
              <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between" }}><strong>Warranty:</strong> {product.btp_warranty_period && product.btp_warranty_period.match(/\d+/) ? product.btp_warranty_period.match(/\d+/)[0] : 'N/A'} Months</p>
              <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between" }}><strong>Series:</strong> {product.bts_series_name}</p>
              <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between" }}><strong>Discount:</strong> {product.btp_max_discount && product.btp_max_discount.match(/\d+/) ? product.btp_max_discount.match(/\d+/)[0] : 'N/A'}%</p>
              <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between" }}><strong>Product Code:</strong> {product.btp_product_code_no}</p>
              <p style={{ display: "flex", marginBottom: '3px',justifyContent: "space-between" }}><strong>Description1:</strong> {product.btp_description1}</p>
              <p style={{ display: "flex", marginBottom: '3px',justifyContent: "space-between" }}><strong>Description2:</strong> {product.btp_description2}</p>
            </div>   {/* second col ends here */}
          </div>
        </div>



        <div style={{ display: "flex", gap: "10px" }}>
          <button
            type="button"
            className="btn btn-warning"
            style={{ width: "150px" }}
            onClick={onClose}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-outline-warning"
            onClick={handleAddToComparison}
          >
            Add To Comparison
          </button>
          <button
            type="button"
            className="btn btn-outline-warning"
            onClick={handleShowSimilarProducts}
          >
           Show Similar Products
          </button>
          
        </div>
      {/* Display the message for adding product to comparison */}
      {addProductMessage && (
            <div style={{ marginTop: "10px", color: "green", fontWeight: "bold", textAlign: "center" }}>
              {addProductMessage}
            </div>
          )}
      </div>
{/* SimilarProductModal */}
{/* SimilarProductModal should be included here */}
<SimilarProductModal 
        showSimilarModal={showSimilarModal} 
        product={product} 
        onClose={closeSimilarModal} 
        addProductToComparison={addProductToComparison}
      />

    </div>
  );
};

export default ProductModal;

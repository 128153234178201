import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import './DynBrands.css';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';


export default function Items() {
  const [Info, setInfo] = useState([]);
  const [ItemId, setItemId] = useState(null); // State to hold the selected brandId
  const [ItemName, setItemName] = useState('')
  const scrollContainerRef = useRef(null); // Reference for the scrollable container

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const res = await axios.post('http://localhost:9000/proxy/brandpoint', {
        //  const res = await axios.post('http://192.168.0.139:9000/proxy/brandpoint', {
        const res = await axios.post('http://midbserver.co.in:8285/trading_application/rest/menuService/getBrandMasterReportDetails', {
          two:"Active",
          authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
        });
        setInfo(res.data.jsArray); // Store response data in state
        console.log(res.data.jsArray); // To verify the structure of response
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  // Function to scroll the container to the right
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: window.innerWidth <= 768 ? 150 : 200, // Adjust scroll distance based on screen size
        behavior: 'smooth', // Smooth scrolling effect
      });
    }
  };

  // Function to scroll the container to the left
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: window.innerWidth <= 768 ? -150 : -200, // Adjust scroll distance based on screen size
        behavior: 'smooth', // Smooth scrolling effect
      });
    }
  };


  const handleItemClick = (id, name) => {
    // Set sessionStorage for the clicked item's id and name
    sessionStorage.setItem('itemId', id);
    sessionStorage.setItem('itemName', name);
  };

  return (
    <>

      <div>
        <div class="container ">
          <div className='container' style={{paddingTop:"100px"}}>
        <h2 style={{ color: "rgb(255, 0, 79)", fontWeight: "900px", textAlign: "center" }}>Our Brands</h2>
        <div style={{ marginTop: "0px" }}>
        </div>
          <div className="container  d-flex justify-content-center align-items-center" style={{ position: 'relative', marginBottom: "0px",width:"100%" }} >
            {/* Scrollable container */}
            <div
              ref={scrollContainerRef}
              className="row d-flex justify-content-start align-items-center"
              style={{
                overflowX: 'auto', // Allow horizontal scrolling
                display: 'flex',
                flexWrap: 'nowrap',
                paddingBottom: '0px',
                scrollBehavior: 'smooth', // Smooth scroll when user clicks buttons
                width: '100%', // Ensure the container takes up full width
                scrollbarWidth: "none",
                marginBottom: "0px"
              }}
            >
              {Info.length > 0 ? (
                Info.map((item, index) => (
                  <div
                    className="col-12 col-lg-2 col-md-4 col-sm-6"
                    key={index}
                    style={{
                      display: 'inline-block',
                      marginRight: '40px',
                      minWidth: '180px', // Adjust min-width for responsiveness
                    }}
                  >
                    <div className="d-flex flex-column mb-3">
                      {/* Image section */}
                      <Link to="/dynB">
                      <div className="p-2 d-flex justify-content-evenly">
                         {/* <Link to="/dynB">  */}
                        <img
                          // src={item.pdt_brnd_img || 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='} // Fallback image if not available
                          src={item.pdt_brnd_img ? `http://midbserver.co.in:8285/MI_Applications/BTrading/BrandLogo/2024/December/${item.pdt_brnd_img}` : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='}
                          alt={item.pdt_brnd_name}
                          className="transition-imageIN"
                          onClick={() => handleItemClick(item.pdt_brnd_id, item.pdt_brnd_name)}
                          style={{
                            height: '150px',
                            width: '150px',
                            boxShadow: '5px 5px 10px gray',
                            borderRadius: "50%",
                            padding: '0px',
                            cursor: 'pointer'
                          }}
                        />
                        
                         {/* </Link>  */}
                      </div>
                      </Link>


                      {/* Brand name section */}
                      <div className="p-0" style={{ textAlign: 'center' }}>
  <p
    className="nav-linked"
    style={{
      fontSize: '15px',
      color: "black",
      fontWeight: "700",
      cursor: "pointer"
    }}
  >
    <Link
      to="/dynB"
      onClick={() => handleItemClick(item.pdt_brnd_id, item.pdt_brnd_name)} // Use an arrow function
    >
      <b>{item.pdt_brnd_name}</b>
    </Link>
  </p>
</div>

                    </div>
                  </div>
                ))
              ) : (
                <p>Loading data...</p>
              )}
            </div>

            {/* Left Scroll Button */}
            <div
              style={{
                position: 'absolute',
                left: '-75px',
                top: '35%',
                transform: 'translateY(-50%)',
                backgroundColor: '#0d778e',
                border: 'none',
                borderRadius: '50%',
                padding: '10px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 1, // Ensure it's on top of the content
              }}
              onClick={scrollLeft}
            >
              <FaChevronLeft color="white" size={20} />
            </div>

            {/* Right Scroll Button */}
            <div
              style={{
                position: 'absolute',
                right: '-75px',
                top: '35%',
                transform: 'translateY(-50%)',
                backgroundColor: '#0d778e',
                border: 'none',
                borderRadius: '50%',
                padding: '10px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 1, // Ensure it's on top of the content
              }}
              onClick={scrollRight}
            >
              <FaChevronRight color="white" size={20} />
            </div>

          </div>

        </div>
      </div>
      </div>

      <div className='container  py-5'>
     <div className='container '>
     <div className='container'>
    <div className='row mt-4 d-flex justify-content-center'>
        <div className='col col-12 col-md-8 col-lg-5 '>
            <img 
                src='https://media.istockphoto.com/id/1221362919/photo/white-bathroom-with-bath-and-large-window.jpg?s=612x612&w=0&k=20&c=r7TzhDH5UZ4gLhC6QopMbUVSz1fEPAiDfg5uQwsiCNc=' 
                style={{borderRadius:"10px", width:"100%", height:"auto"}}
                alt="Bathroom"
            />
        </div>
        <div className='col-12 col-md-0 col-lg-2'>
            {/* You can put content here if needed */}
        </div>
        <div className='col-12 col-md-8 col-lg-5'>
            <h3 style={{color:"#ff004f"}}>
            Check out the best Bathroom Sanitary Ware in India
            </h3>
            <p style={{color:"gray", fontFamily:"Poppins"}}>
            Kindly provide the required data for display in this section.Kindly provide the required data for display in this section.Kindly provide the required data for display in this section.
            </p>
        </div>
    </div>
</div>

    
      </div>
    </div>
      
    </>

  );
}

import React from 'react'

export default function InfoCard() {
  return (
    <>
{/* <div className='container'>
    <div className='row mt-4 d-flex justify-content-center p-3'>
        <div className='col col-12 col-md-8 col-lg-5 '>
            <img 
                src='https://media.istockphoto.com/id/1221362919/photo/white-bathroom-with-bath-and-large-window.jpg?s=612x612&w=0&k=20&c=r7TzhDH5UZ4gLhC6QopMbUVSz1fEPAiDfg5uQwsiCNc=' 
                style={{borderRadius:"10px", width:"100%", height:"auto"}}
                alt="Bathroom"
            />
        </div>
        <div className='col-12 col-md-8 col-lg-5'>
            <h3 style={{color:"#ff004f"}}>
            Check out the best Bathroom Sanitary Ware in India
            </h3>
            <p style={{color:"gray", fontFamily:"Poppins"}}>
            Kindly provide the required data for display in this section.Kindly provide the required data for display in this section.Kindly provide the required data for display in this section.
            </p>
        </div>
    </div>
</div> */}


        {/*  container div end here */}
    </>
  )
}
